<template>
  <div style="height: 100vh;" class="column no-wrap justify-between">
    <q-dialog v-model="confirm" persistent>
      <q-card class="q-py-md q-px-sm">
        <q-card-section>
          Apakah Anda akan keluar dari halaman survey?
        </q-card-section>
        <q-card-section class="row justify-evenly">
          <q-btn dense outline color="indigo-9" style="width: 80px;" no-caps @click="confirm = false" label="Tidak" />
          <q-btn dense unelevated color="indigo-9" style="width: 80px;" no-caps @click="closeSurvey" label="Ya" />
        </q-card-section>
      </q-card>
    </q-dialog>
    <div class="row items-center absolute-top-right q-mr-md">
      <div class="text-grey q-pr-xs">{{ surveyor.nama }}</div>
      |
      <q-btn dense flat @click="confirm = true" color="red" label="logout" no-caps />
    </div>
    <div class="col-auto q-pa-md q-pt-xl top text-center">
      <div class="text-h6">
        HASIL SURVEY
      </div>
      <div>Nama Guru Kepala: {{ surveyor.nama }}</div>
    </div>
    <div class="col-auto q-pa-md middle">
      <div v-for="(x, i) in result_survey" :key="i">
        <div :style="x.id ? (x.nilai_survey > 30 ? 'color: red' : '') : 'color: grey'" class="row q-py-sm">
          <p :style="'padding-right: 5px; top: -0.6em;font-size: .83em; ' + (x.nilai_survey > 30 ? '' : 'color:green')">
            {{ x.id ? x.nilai_survey : "-" }}
          </p>
          <p :class="'ellipsis '">{{ x.nama }}</p>
          <q-btn dense v-show="x.masih_karantina" flat icon="masks" color="negative" />
          <!-- masih:{{x.masih_karantina}} ,karantina:{{x.karantina}} asd -->
        </div>
      </div>
    </div>
    <div class="col-2 bottom  row items-end">
      <div class="row" style="width: 100%;">
        <q-btn
          class="col-12 q-pa-sm no-border-radius"
          label="Survey Mandiri"
          color="indigo-10"
          @click="$router.push('/survey-guru')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "App",

  data() {
    return {
      confirm: false,
      surveyor: {},
      step: 2,
      survey: { Q1: false, Q2: false, Q3: false, Q4: false, Q5: false, nilai_survey: 35 },
      result_survey: [],
      result_masih_karantina: [],
    };
  },
  mounted() {
    this.$http.get("/kode_unik", {}).then((result) => {
      if (localStorage.getItem("kode_unik") != result.data.kode_unik) {
        this.$router.push("/");
      }
    });
    this.$http.get("/guru_by_noinduk/" + localStorage.getItem("surveyor"), {}).then((result) => {
      this.surveyor = result.data;
    });

    this.$http.get("/hasil_survey_guru/" + localStorage.getItem("surveyor"), {}).then((result) => {
      this.result_survey = result.data;
      this.$http.get("/guru_masih_karantina/" + localStorage.getItem("surveyor"), {}).then((result) => {
        this.result_masih_karantina = result.data;
        // console.log(this.result_masih_karantina)
        // console.log(this.result_survey)
        console.log("disini");
        this.result_survey.forEach((x, i) => {
          console.log(i);
          this.result_masih_karantina.forEach((y) => {
            if (x.no_induk == y.no_induk) {
              console.log("ada nih");
              // x.masih_karantina = true;
              this.$set(x,'masih_karantina', true)
              // this.$set(this.result_survey[i],'masih_karantina', true)
              // this.$forceUpdate();
            }
          });
        });
      });
    });
  },
  methods: {
    closeSurvey() {
      localStorage.removeItem("kode_unik");
      localStorage.removeItem("surveyor");
      this.$router.push("/");
    },
    onSubmit() {
      console.log("tes");
      this.step = 2;
      let val =
        (this.survey.Q1 ? 10 : 0) +
        (this.survey.Q2 ? 10 : 0) +
        (this.survey.Q3 ? 10 : 0) +
        (this.survey.Q4 ? 10 : 0) +
        (this.survey.Q5 ? 10 : 0);
      var data = {
        no_kamar: this.surveyor.no_kamar,
        no_induk: this.surveyor.no_induk,
        Q1: this.survey.Q1 ? "Y" : "N",
        Q2: this.survey.Q2 ? "Y" : "N",
        Q3: this.survey.Q3 ? "Y" : "N",
        Q4: this.survey.Q4 ? "Y" : "N",
        Q5: this.survey.Q5 ? "Y" : "N",
        nilai_survey: val,
        surveyor: this.surveyor.nama,
      };
      if (val > 30) {
        data.karantina = "Y";
        data.tgl_karantina = moment().format("YYYY-MM-DD");
      }
      this.$http.post("/new_survey/", data, {}).then((result) => {});
      this.survey.nilai_survey = val;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Montserrat";
  src: url("~@/assets/fonts/Montserrat-Medium.otf") format("truetype");
}

* {
  font-family: "Montserrat";
}
.top {
  // min-height: 100px;
  // padding: 5px 18px;
  // overflow-y: auto;
}
.middle {
  background-color: rgb(230, 230, 230);
  // padding: 10px;
  overflow: scroll;
  flex-grow: 99;
}
.bottom {
  // height: 100px;
}

.custom-area {
  width: 90%;
  height: 220px;
  border-radius: 3px;
  padding: 8px;
}

.custom-info pre {
  width: 180px;
  font-size: 12px;
}
</style>
